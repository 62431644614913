<template>
  <div
    :class="$viewport.gt.md ? 'd-flex' : ''"
  >
    <div :class="$viewport.gt.md ? 'flex-grow-1' : ''">
      <ElButton
        :size="preSelectedUnit ? 'medium' : 'large'"
        :class="$viewport.gt.md ? '' : 'w-100'"
        @click="redirectToProperties"
      >
        I'll Do It Later
      </ElButton>
    </div>
    <div
      v-if="preSelectedUnit"
      :class="$viewport.gt.md ? 'flex-grow-1' : ''"
    >
      <ElButton
        type="primary"
        :size="preSelectedUnit ? 'medium' : 'large'"
        :class="$viewport.gt.md ? '' : 'w-100 mt-4'"
        :disabled="disabled"
        @click="redirectToSetup"
      >
        Continue set-up
      </ElButton>
    </div>
    <div
      v-else
      :class="$viewport.gt.md ? 'd-flex select-unit-for-showing-block' : 'mt-4'"
    >
      <SdFloatLabel>
        <ElSelect
          v-model="selectedUnitRef"
          placeholder="Set up showing for:"
        >
          <ElOption
            v-for="item in property.units"
            :key="item.id"
            :label="item.name"
            :value="item.id"
            :disabled="Boolean(item.activated_at)"
          />
        </ElSelect>
      </SdFloatLabel>

      <ElButton
        :size="preSelectedUnit ? 'medium' : 'large'"
        :class="$viewport.gt.md ? '' : 'w-100 mt-4'"
        type="primary"
        :disabled="selectedUnitRef === null || disabled"
        @click="redirectToSetup"
      >
        Continue
      </ElButton>
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api';
import { redirectToPropertiesPage } from '@/router';
import { SHOWING_PROFILE_ROUTES } from '@/router/routes';
import { isBusinessOnlyUsingAgentShowings, isBusinessSelfShowingsEnabled } from '@/utils/BusinessUtil';
import SdFloatLabel from '@/components/common/form/SdFloatLabel';
import Plan from '@/constants/Plan';

export default {
  name: 'ShowingProfileSetupButtons',
  components: { SdFloatLabel },
  props: {
    property: {
      type: Object,
      required: false,
      default: null,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    preSelectedUnit: {
      type: Object,
      default: undefined,
    },
  },
  setup(props, context) {
    const selectedUnitRef = ref((props.preSelectedUnit && props.preSelectedUnit.id) || getTheFirstInActiveUnit(props.property.units)?.id);

    return {
      selectedUnitRef,
      redirectToProperties,
      redirectToSetup,
    };

    function getTheFirstInActiveUnit(units) {
      return units.find((unit) => Boolean(unit.activated_at) === false);
    }

    function redirectToProperties() {
      redirectToPropertiesPage(context);
    }

    function redirectToSetup() {
      context.root.$router.push({
        name: isBusinessOnlyUsingAgentShowings() ? SHOWING_PROFILE_ROUTES.AGENT_SHOWING_PAGE : SHOWING_PROFILE_ROUTES.TYPE_SELECTION_PAGE,
        params: {
          id: context.root.$route.params.id,
          unit_id: selectedUnitRef.value,
        },
      });
    }
  },
};
</script>

<style lang="scss"></style>
